import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { AiOutlinePlus } from 'react-icons/ai';
import Lightbox from 'react-image-lightbox';

const imagesLoc1 = [
    'assets/img/location/kamal-vihar-2000-img1.jpg',
    'assets/img/location/kamal-vihar-2000-img2.jpg',
    'assets/img/location/kamal-vihar-2000-img3.jpg',
    'assets/img/location/kamal-vihar-2000-img4.jpg',
    'assets/img/location/kamal-vihar-2000-img5.jpg',
    'assets/img/location/kamal-vihar-4800-img6.jpg',
    'assets/img/location/kamal-vihar-4800-img7.jpg',
    'assets/img/location/kamal-vihar-4800-img9.jpg',
    'assets/img/location/kamal-vihar-4800-img10.jpg',
];

const imagesLoc2 = [
    'assets/img/location/kamal-vihar-6000-img1.jpg',
    'assets/img/location/kamal-vihar-6000-img2.jpg',
    'assets/img/location/kamal-vihar-6000-img3.jpg',
    'assets/img/location/kamal-vihar-6000-img4.jpg',
    'assets/img/location/kamal-vihar-6000-img5.jpg',
    'assets/img/location/kamal-vihar-6000-img6.jpg',
    'assets/img/location/kamal-vihar-6000-img7.jpg',
    'assets/img/location/kamal-vihar-6000-img8.jpg',
];

const imagesLoc3 = [
    'assets/img/location/kamal-vihar-4800-img1.jpg',
    'assets/img/location/kamal-vihar-4800-img2.jpg',
    'assets/img/location/kamal-vihar-4800-img3.jpg',
    'assets/img/location/kamal-vihar-4800-img4.jpg',
    'assets/img/location/kamal-vihar-4800-img5.jpg',
    'assets/img/location/kamal-vihar-4800-img6.jpg',
    'assets/img/location/kamal-vihar-4800-img7.jpg',
    'assets/img/location/kamal-vihar-4800-img8.jpg',
    'assets/img/location/kamal-vihar-4800-img9.jpg',
    'assets/img/location/kamal-vihar-4800-img10.jpg',
    'assets/img/location/kamal-vihar-4800-img11.jpg',
    'assets/img/location/kamal-vihar-4800-img12.jpg',
    'assets/img/location/kamal-vihar-4800-img13.jpg',
];

const imagesLoc4 = [
    'assets/img/location/kamal-vihar-6000-3-img1.jpg',
    'assets/img/location/kamal-vihar-6000-3-img2.jpg',
    'assets/img/location/kamal-vihar-6000-3-img3.jpg',
    'assets/img/location/kamal-vihar-6000-3-img4.jpg',
    'assets/img/location/kamal-vihar-6000-3-img5.jpg',
    'assets/img/location/kamal-vihar-6000-3-img6.jpg',
    'assets/img/location/kamal-vihar-6000-3-img7.jpg',
    'assets/img/location/kamal-vihar-6000-3-img8.jpg',
    'assets/img/location/kamal-vihar-6000-3-img9.jpg',
    'assets/img/location/kamal-vihar-4800-img2.jpg',
    'assets/img/location/kamal-vihar-4800-img4.jpg',
    'assets/img/location/kamal-vihar-4800-img6.jpg',
    'assets/img/location/kamal-vihar-4800-img7.jpg',
    'assets/img/location/kamal-vihar-4800-img10.jpg',
    'assets/img/location/kamal-vihar-4800-img12.jpg',
    'assets/img/location/kamal-vihar-4800-img13.jpg',
];

const PropertySection = () => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [images, setImages] = useState(imagesLoc1);
    const [isOpen, setIsOpen] = useState(false);
    const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };

    return (
        <>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}
            <section className="price__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("2000")}>2000 sq.ft</button>
                                <button onClick={() => scrollTo("6000")}>6000 sq.ft</button>
                                <button onClick={() => scrollTo("4800")}>4800 sq.ft</button>
                                <button onClick={() => scrollTo("60003")}>6000 sq.ft (3rd Floor)</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="price__area pt-100 pb-100 fixBox" id="2000">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Book Office Space for Rent in Kamal Vihar Raipur</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="expart__wrapper">
                                <div className="expart__tab">
                                    <div className="tab-content">
                                        <div className="expart__tab-content white-bg">
                                            <div className="expart__content">
                                                <h3>Location - Office No. A42, Sector 2, Kamal Vihar Rd, Near Jainam Park, Lalpur, Raipur, Chhattisgarh 492001</h3>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Unfurnished Office Space for Rent</b>
                                                    <ul>
                                                        <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>40,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>4 months</b></span></li>
                                                        <li><span>Built-up Area - <b>2000 sq.ft</b></span></li>
                                                        <li><span>Carpet Area - <b>2000 sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                                    <ul>
                                                        <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>70,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>4 months</b></span></li>
                                                        <li><span>Built-up Area - <b>2000 sq.ft</b></span></li>
                                                        <li><span>Carpet Area - <b>2000 sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-3">A spacious and unfurnished office space with modern amenities spanning of 2,000 sq.ft is available for rent in second floor of building located in Kamal Vihar, Raipur. This unfurnished office space offers ample flexibility to customize the design and layout that suit's your specific business needs & requirements. The building is secured with 24/7 security and has been carefully considered to create a comfortable and secure environment for work. The strategic location of this office space provides excellent connectivity to all major commercial areas and making it an attractive choice for businesses seeking a prime location in Raipur.</p>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Property Highlights:</b>
                                                    <ul>
                                                        <li><span>Excellent commercial property with essential amenities</span></li>
                                                        <li><span>Ample parking space for 2 & 4-wheelers</span></li>
                                                        <li><span>Passenger lift for easy accessibility to all floors</span></li>
                                                        <li><span>Visitor parking facilities for better clients accommodation</span></li>
                                                        <li><span>Access to vibrant community</span></li>
                                                        <li><span>24*7 Security to ensure safety</span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Space Costing Remarks:</b>
                                                    <ul>
                                                        <li><span>Unfurnished space 14 Rs per sqft</span></li>
                                                        <li><span>Furnished space 29 Rs per sqft</span></li>
                                                        <li><span>Maintenance 2 Rs per sqft</span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__features">
                                                    <b className="d-block mb-10">Additional Remark for Ground Floor Space:</b>
                                                    <p className="mb-0">A thoughtfully planned opportunity! For those considering the ground floor space, we're offering a rate of Rs 8 per sq.ft additionally.</p>
                                                </div>
                                                <div className="price__doc mt-20 mb-40"></div>
                                                <div className="row align-items-center">
                                                    <div className="col-xl-6">
                                                        <div className="price__features mb-10">
                                                            <b className="d-block">Rental</b>
                                                        </div>
                                                        <div className="price__tag">
                                                            <div className="d-flex flex-wrap">
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>40,000/-</h1>
                                                                <span className="ms-1">Unfurnished</span>
                                                            </div>
                                                            <div className="d-flex flex-wrap mt-5">
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>70,000/-</h1>
                                                                <span className="ms-1">Fully Furnished</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="text-lg-end mt-lg-0 mt-30">
                                                            <Link to="/contact" className="z-btn">Book Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="portfolioBox sticky-top">
                                {imagesLoc1.slice(0, 3).map((img, index) => (
                                    <div key={index} className="portfolio__item p-relative">
                                        <div className="portfolio__thumb w-img fix">
                                            <img src={img} alt="Premisin" />
                                            <div className="portfolio__plus p-absolute transition-3">
                                                <button
                                                    onClick={() => {
                                                        openLightbox(index);
                                                        setImages(imagesLoc1);
                                                    }}
                                                >
                                                    <i><AiOutlinePlus /></i>
                                                    <i><AiOutlinePlus /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <span>+6 More</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="price__area grey-bg-12 pt-100 pb-100 fixBox" id="6000">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Book Office Space for Rent in Kamal Vihar Raipur</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="expart__wrapper">
                                <div className="expart__tab">
                                    <div className="tab-content">
                                        <div className="expart__tab-content white-bg">
                                            <div className="expart__content">
                                                <h3>Location - Office No. A42, Sector 2, Kamal Vihar Rd, Near Jainam Park, Lalpur, Raipur, Chhattisgarh 492001</h3>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Unfurnished Office Space for Rent</b>
                                                    <ul>
                                                        <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>1,00,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>4 months</b></span></li>
                                                        <li><span>Built-up Area - <b>6000 sq.ft</b></span></li>
                                                        <li><span>Carpet Area - <b>6000 sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                                    <ul>
                                                        <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>2,00,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>4 months</b></span></li>
                                                        <li><span>Built-up Area - <b>6000 sq.ft</b></span></li>
                                                        <li><span>Carpet Area - <b>6000 sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-3">A spacious and unfurnished office space with modern amenities spanning of 6,000 sq.ft is available for lease in Kamal Vihar, Raipur. This unfurnished office space offers ample flexibility to customize the design and layout that suit's your specific business needs & requirements. The building is secured with 24/7 security and has been carefully considered to create a comfortable and secure environment for work. The strategic location of this office space provides excellent connectivity to all major commercial areas and making it an attractive choice for businesses seeking a prime location in Raipur.</p>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Property Highlights:</b>
                                                    <ul>
                                                        <li><span>Excellent commercial property with essential amenities</span></li>
                                                        <li><span>Ample parking space for 2 & 4-wheelers</span></li>
                                                        <li><span>Passenger lift for easy accessibility to all floors</span></li>
                                                        <li><span>Visitor parking facilities for better clients accommodation</span></li>
                                                        <li><span>Convenient Ground Floor Location</span></li>
                                                        <li><span>Access to vibrant community</span></li>
                                                        <li><span>24*7 Security to ensure safety</span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Space Costing Remarks:</b>
                                                    <ul>
                                                        <li><span>Unfurnished space 14 Rs per sqft</span></li>
                                                        <li><span>Furnished space 29 Rs per sqft</span></li>
                                                        <li><span>Maintenance 2 Rs per sqft</span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__features">
                                                    <b className="d-block mb-10">Additional Remark for Extra Ground Floor Space:</b>
                                                    <p className="mb-0">A thoughtfully planned opportunity! For those considering extra ground floor space, we're offering a rate of Rs 8 per sq ft additionally.</p>
                                                </div>
                                                <div className="price__doc mt-20 mb-40"></div>
                                                <div className="row align-items-center">
                                                    <div className="col-xl-6">
                                                        <div className="price__features mb-10">
                                                            <b className="d-block">Rental</b>
                                                        </div>
                                                        <div className="price__tag">
                                                            <div className="d-flex flex-wrap">
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>1,00,000/-</h1>
                                                                <span className="ms-1">Unfurnished</span>
                                                            </div>
                                                            <div className="d-flex flex-wrap mt-5">
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>2,00,000/-</h1>
                                                                <span className="ms-1">Fully Furnished</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="text-lg-end mt-lg-0 mt-30">
                                                            <Link to="/contact" className="z-btn">Book Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="portfolioBox sticky-top">
                                {imagesLoc2.slice(0, 3).map((img, index) => (
                                    <div key={index} className="portfolio__item p-relative">
                                        <div className="portfolio__thumb w-img fix">
                                            <img src={img} alt="Premisin" />
                                            <div className="portfolio__plus p-absolute transition-3">
                                                <button
                                                    onClick={() => {
                                                        openLightbox(index);
                                                        setImages(imagesLoc2);
                                                    }}
                                                >
                                                    <i><AiOutlinePlus /></i>
                                                    <i><AiOutlinePlus /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <span>+5 More</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="price__area pt-100 pb-100 fixBox" id="4800">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Unfurnished Bareshell Office for Rent in Kamal Vihar Raipur</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="expart__wrapper">
                                <div className="expart__tab">
                                    <div className="tab-content">
                                        <div className="expart__tab-content white-bg">
                                            <div className="expart__content">
                                                <h3>Location - 2<sup>nd</sup> Floor, Office No. A42, Sector 2, Kamal Vihar Rd, Near Jainam Park, Lalpur, Raipur, Chhattisgarh 492001</h3>
                                                <div className="price__features mb-20">
                                                    <ul>
                                                        <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>90,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>4 months</b></span></li>
                                                        <li><span>Built-up Area - <b>4800 sq.ft</b></span></li>
                                                        <li><span>Maintenance - <b><i><MdOutlineCurrencyRupee /></i>2 per sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-3">A spacious and unfurnished office space with modern amenities spanning of 4,800 sq.ft is available for lease in Kamal Vihar, Raipur. This unfurnished office space offers ample flexibility to customize the design and layout that suit's your specific business needs & requirements. The building is secured with 24/7 security and has been carefully considered to create a comfortable and secure environment for work. The strategic location of this office space provides excellent connectivity to all major commercial areas and making it an attractive choice for businesses seeking a prime location in Raipur.</p>
                                                <div className="price__features">
                                                    <b className="d-block mb-10">Property Highlights:</b>
                                                    <ul>
                                                        <li><span>Excellent commercial property with essential amenities</span></li>
                                                        <li><span>Ample parking space for 2 & 4-wheelers</span></li>
                                                        <li><span>Passenger lift for easy accessibility to all floors</span></li>
                                                        <li><span>Visitor parking facilities for better clients accommodation</span></li>
                                                        <li><span>Access to vibrant community</span></li>
                                                        <li><span>24*7 Security to ensure safety</span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__doc mt-20 mb-40"></div>
                                                <div className="row align-items-center">
                                                    <div className="col-xl-6">
                                                        <div className="price__features mb-10">
                                                            <b className="d-block">Rental</b>
                                                        </div>
                                                        <div className="price__tag">
                                                            <div>
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>90,000/-</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="text-lg-end mt-lg-0 mt-30">
                                                            <Link to="/contact" className="z-btn">Book Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="portfolioBox sticky-top">
                                {imagesLoc3.slice(0, 3).map((img, index) => (
                                    <div key={index} className="portfolio__item p-relative">
                                        <div className="portfolio__thumb w-img fix">
                                            <img src={img} alt="Premisin" />
                                            <div className="portfolio__plus p-absolute transition-3">
                                                <button
                                                    onClick={() => {
                                                        openLightbox(index);
                                                        setImages(imagesLoc3);
                                                    }}
                                                >
                                                    <i><AiOutlinePlus /></i>
                                                    <i><AiOutlinePlus /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <span>+10 More</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="price__area grey-bg-12 pt-100 pb-100 fixBox" id="60003">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Fully Furnished Office for Rent in Kamal Vihar Raipur</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="expart__wrapper">
                                <div className="expart__tab">
                                    <div className="tab-content">
                                        <div className="expart__tab-content white-bg">
                                            <div className="expart__content">
                                                <h3>Location - 3<sup>rd</sup> Floor, Office No. A42, Sector 2, Kamal Vihar Rd, Near Jainam Park, Lalpur, Raipur, Chhattisgarh 492001</h3>
                                                <div className="price__features mb-20">
                                                    <ul>
                                                        <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>2,00,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>5 months</b></span></li>
                                                        <li><span>Built-up Area - <b>6000 sq.ft</b></span></li>
                                                        <li><span>Maintenance - <b><i><MdOutlineCurrencyRupee /></i>3 per sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-3">A spacious and fully furnished premium office space with modern amenities spanning of 6,000 sq.ft is available for lease at Kamal Vihar, Raipur. This office space offers newly furnished and constructed office space. The building is secured with 24/7 security and has been carefully considered to create a comfortable and secure environment for work.</p>
                                                <div className="price__features">
                                                    <b className="d-block mb-10">Property Highlights:</b>
                                                    <ul>
                                                        <li><span>70-80 Workstations</span></li>
                                                        <li><span>1200 sq ft premium boss cabin</span></li>
                                                        <li><span>1 Conference room</span></li>
                                                        <li><span>1 meeting room</span></li>
                                                        <li><span>Separate male and female washrooms</span></li>
                                                        <li><span>Lobby with Pool table and Foos Ball</span></li>
                                                        <li><span>AC, Table, Chair, Pedstals, Networking, fancy lights</span></li>
                                                        <li><span>Excellent commercial property with essential amenities</span></li>
                                                        <li><span>Ample parking space for 2 & 4-wheelers</span></li>
                                                        <li><span>Passenger lift for easy accessibility to all floors</span></li>
                                                        <li><span>Visitor parking facilities for better clients accommodation </span></li>
                                                        <li><span>Access to vibrant community</span></li>
                                                        <li><span>24*7 Security to ensure safety </span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__doc mt-20 mb-40"></div>
                                                <div className="row align-items-center">
                                                    <div className="col-xl-6">
                                                        <div className="price__features mb-10">
                                                            <b className="d-block">Rental</b>
                                                        </div>
                                                        <div className="price__tag">
                                                            <div>
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>2,00,000/-</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="text-lg-end mt-lg-0 mt-30">
                                                            <Link to="/contact" className="z-btn">Book Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="portfolioBox sticky-top">
                                {imagesLoc4.slice(0, 3).map((img, index) => (
                                    <div key={index} className="portfolio__item p-relative">
                                        <div className="portfolio__thumb w-img fix">
                                            <img src={img} alt="Premisin" />
                                            <div className="portfolio__plus p-absolute transition-3">
                                                <button
                                                    onClick={() => {
                                                        openLightbox(index);
                                                        setImages(imagesLoc4);
                                                    }}
                                                >
                                                    <i><AiOutlinePlus /></i>
                                                    <i><AiOutlinePlus /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <span>+13 More</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PropertySection;