import React from 'react';
import PageHelmet from '../Helper/PageHelmet';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import HeadSection from './HeadSection';
import ContactSection from './ContactSection';

const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="Contact Sjain Office Space" />
            <Header />
            <HeadSection />
            <ContactSection />
            <Footer />
        </>
    );
};

export default Home;