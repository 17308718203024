import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { AiOutlinePlus } from 'react-icons/ai';
import Lightbox from 'react-image-lightbox';

const imagesLoc1 = [
    'assets/img/location/vallabh-nagar-200-img1.jpg',
    'assets/img/location/vallabh-nagar-200-img2.jpg',
    'assets/img/location/vallabh-nagar-200-img3.jpg',
];

const imagesLoc2 = [
    'assets/img/location/vallabh-nagar-400-img1.jpg',
    'assets/img/location/vallabh-nagar-400-img2.jpg',
    'assets/img/location/vallabh-nagar-400-img3.jpg',
    'assets/img/location/vallabh-nagar-400-img4.jpg',
    'assets/img/location/vallabh-nagar-400-img5.jpg',
    'assets/img/location/vallabh-nagar-400-img6.jpg',
    'assets/img/location/vallabh-nagar-400-img7.jpg',
    'assets/img/location/vallabh-nagar-400-img8.jpg',
    'assets/img/location/vallabh-nagar-400-img9.jpg',
];

const imagesLoc3 = [
    'assets/img/location/vallabh-nagar-800-img1.jpg',
    'assets/img/location/vallabh-nagar-800-img2.jpg',
    'assets/img/location/vallabh-nagar-800-img3.jpg',
    'assets/img/location/vallabh-nagar-800-img4.jpg',
    'assets/img/location/vallabh-nagar-800-img5.jpg',
    'assets/img/location/vallabh-nagar-800-img6.jpg',
    'assets/img/location/vallabh-nagar-800-img7.jpg',
    'assets/img/location/vallabh-nagar-800-img8.jpg',
    'assets/img/location/vallabh-nagar-400-img6.jpg',
    'assets/img/location/vallabh-nagar-400-img7.jpg',
    'assets/img/location/vallabh-nagar-400-img8.jpg',
    'assets/img/location/vallabh-nagar-400-img9.jpg',
];

const imagesLoc4 = [
    'assets/img/location/vallabh-nagar-4000-img1.jpg',
    'assets/img/location/vallabh-nagar-4000-img2.jpg',
    'assets/img/location/vallabh-nagar-4000-img3.jpg',
    'assets/img/location/vallabh-nagar-4000-img4.jpg',
    'assets/img/location/vallabh-nagar-4000-img5.jpg',
    'assets/img/location/vallabh-nagar-4000-img6.jpg',
    'assets/img/location/vallabh-nagar-4000-img7.jpg',
    'assets/img/location/vallabh-nagar-4000-img8.jpg',
    'assets/img/location/vallabh-nagar-4000-img9.jpg',
    'assets/img/location/vallabh-nagar-4000-img10.jpg',
    'assets/img/location/vallabh-nagar-4000-img11.jpg',
    'assets/img/location/vallabh-nagar-4000-img12.jpg',
    'assets/img/location/vallabh-nagar-4000-img13.jpg',
    'assets/img/location/vallabh-nagar-4000-img14.jpg',
    'assets/img/location/vallabh-nagar-400-img6.jpg',
    'assets/img/location/vallabh-nagar-800-img2.jpg',
    'assets/img/location/vallabh-nagar-800-img1.jpg',
];

const PropertySection = () => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [images, setImages] = useState(imagesLoc1);
    const [isOpen, setIsOpen] = useState(false);
    const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };

    return (
        <>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}
            <section className="price__area sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="scrollLinks">
                                <button onClick={() => scrollTo("200")}>200 sq.ft</button>
                                <button onClick={() => scrollTo("400")}>400 sq.ft</button>
                                <button onClick={() => scrollTo("800")}>800 sq.ft</button>
                                <button onClick={() => scrollTo("4000")}>4000 sq.ft</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="price__area pt-100 pb-100 fixBox" id="200">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Get Fully Furnished Office Space for Rent in Vallabh Nagar Raipur</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="expart__wrapper">
                                <div className="expart__tab">
                                    <div className="tab-content">
                                        <div className="expart__tab-content white-bg">
                                            <div className="expart__content">
                                                <h3>Location - C245/6, Vallabh Nagar, Near Priyadarshini Nagar, Pachpedi Naka, Raipur, Chhattisgarh 492001</h3>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                                    <ul>
                                                        <li><span>Rental - With Service <b><i><MdOutlineCurrencyRupee /></i>18,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>4 months</b></span></li>
                                                        <li><span>Built-up Area - <b>200 sq.ft</b></span></li>
                                                        <li><span>Carpet Area - <b>150 sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-3">200 sq.ft fully furnished office space is available for rent in a centrally located building in the city of Raipur. This workspace combines modern aesthetics with practical design, creating an environment that promotes productivity and professionalism. The office features elegant marble flooring, well-planned workstation layout, and a refreshing recreational area on the terrace. Additionally, 24/7 security is provided to ensure a safe working environment. These thoughtful features have been carefully considered to establish an inspiring and comfortable setting for work and collaboration.</p>
                                                <div className="price__features">
                                                    <b className="d-block mb-10">Property Highlights:</b>
                                                    <ul>
                                                        <li><span>Excellent commercial Property</span></li>
                                                        <li><span>5 Workstations</span></li>
                                                        <li><span>Convenient Ground Floor Location</span></li>
                                                        <li><span>Ample Parking for Both 2 and 4-Wheeler Vehicles</span></li>
                                                        <li><span>Dedicated Visitor Parking</span></li>
                                                        <li><span>Washroom Facilities</span></li>
                                                        <li><span>24*7 security</span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__doc mt-20 mb-40"></div>
                                                <div className="row align-items-center">
                                                    <div className="col-xl-6">
                                                        <div className="price__features mb-10">
                                                            <b className="d-block">Rental</b>
                                                        </div>
                                                        <div className="price__tag">
                                                            <div>
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>18,000/-</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="text-lg-end mt-lg-0 mt-30">
                                                            <Link to="/contact" className="z-btn">Book Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="portfolioBox sticky-top">
                                {imagesLoc1.slice(0, 3).map((img, index) => (
                                    <div key={index} className="portfolio__item p-relative">
                                        <div className="portfolio__thumb w-img fix">
                                            <img src={img} alt="Premisin" />
                                            <div className="portfolio__plus p-absolute transition-3">
                                                <button
                                                    onClick={() => {
                                                        openLightbox(index);
                                                        setImages(imagesLoc1);
                                                    }}
                                                >
                                                    <i><AiOutlinePlus /></i>
                                                    <i><AiOutlinePlus /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="price__area grey-bg-12 pt-100 pb-100 fixBox" id="400">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Book Fully Furnished Office Space for Rent in Vallabh Nagar Raipur</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="expart__wrapper">
                                <div className="expart__tab">
                                    <div className="tab-content">
                                        <div className="expart__tab-content white-bg">
                                            <div className="expart__content">
                                                <h3>Location - C245/6, Vallabh Nagar, Near Priyadarshini Nagar, Pachpedi Naka, Raipur, Chhattisgarh 492001</h3>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                                    <ul>
                                                        <li><span>Rental - Without Service <b><i><MdOutlineCurrencyRupee /></i>20,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>4 months</b></span></li>
                                                        <li><span>Built-up Area - <b>400 sq.ft</b></span></li>
                                                        <li><span>Carpet Area - <b>300 sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-3">400 sq.ft Fully Furnished office space for rent is available in a building located in the heart of the city of Raipur. this workspace seamlessly blends modern aesthetics with practical design, creating an environment that fosters productivity and professionalism. The elegance of marble flooring with thoughtful workstation layout and the refreshing recreational area on the terrace, 24* 7 security this features has been carefully considered to create an inspiring and comfortable setting for work and collaboration.</p>
                                                <div className="price__features">
                                                    <b className="d-block mb-10">Property Highlights:</b>
                                                    <ul>
                                                        <li><span>Excellent Commercial Property with all the essential amenities</span></li>
                                                        <li><span>10 Workstations</span></li>
                                                        <li><span>Lifts for convenience</span></li>
                                                        <li><span>Ample Parking for Both 2 and 4-Wheeler Vehicles</span></li>
                                                        <li><span>Dedicated Visitor Parking</span></li>
                                                        <li><span>Washroom Facility</span></li>
                                                        <li><span>24*7 security for safety assurance</span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__doc mt-20 mb-40"></div>
                                                <div className="row align-items-center">
                                                    <div className="col-xl-6">
                                                        <div className="price__features mb-10">
                                                            <b className="d-block">Rental</b>
                                                        </div>
                                                        <div className="price__tag">
                                                            <div>
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>20,000/-</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="text-lg-end mt-lg-0 mt-30">
                                                            <Link to="/contact" className="z-btn">Book Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="portfolioBox sticky-top">
                                {imagesLoc2.slice(0, 3).map((img, index) => (
                                    <div key={index} className="portfolio__item p-relative">
                                        <div className="portfolio__thumb w-img fix">
                                            <img src={img} alt="Premisin" />
                                            <div className="portfolio__plus p-absolute transition-3">
                                                <button
                                                    onClick={() => {
                                                        openLightbox(index);
                                                        setImages(imagesLoc2);
                                                    }}
                                                >
                                                    <i><AiOutlinePlus /></i>
                                                    <i><AiOutlinePlus /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <span>+6 More</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="price__area pt-100 pb-100 fixBox" id="800">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Get Fully Furnished Office Space for Rent in Vallabh Nagar Raipur</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="expart__wrapper">
                                <div className="expart__tab">
                                    <div className="tab-content">
                                        <div className="expart__tab-content white-bg">
                                            <div className="expart__content">
                                                <h3>Location - C245/6, Vallabh Nagar, Near Priyadarshini Nagar, Pachpedi Naka, Raipur, Chhattisgarh 492001</h3>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                                    <ul>
                                                        <li><span>Rental - Without Service <b><i><MdOutlineCurrencyRupee /></i>22,000/-</b></span></li>
                                                        <li><span>Rental - With Service <b><i><MdOutlineCurrencyRupee /></i>30,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>4 months</b></span></li>
                                                        <li><span>Built-up Area - <b>800 sq.ft</b></span></li>
                                                        <li><span>Carpet Area - <b>700 sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-3">800 sq.ft Fully Furnished office space for rent is available in a building located in the heart of the city of Raipur. This workspace seamlessly blends modern aesthetics with practical design, creating an environment that fosters productivity and professionalism. The elegance of marble flooring with thoughtful workstation layout and the refreshing recreational area on the terrace, 24* 7 security features of this property have been carefully considered to create an inspiring and comfortable setting for work and collaboration.</p>
                                                <div className="price__features">
                                                    <b className="d-block mb-10">Property Highlights:</b>
                                                    <ul>
                                                        <li><span>Excellent Commercial Property with high class amenities</span></li>
                                                        <li><span>2 Executive Cabins and 7 Workstations</span></li>
                                                        <li><span>Convenient Ground Floor Location</span></li>
                                                        <li><span>Ample Parking for Both 2 and 4-Wheeler Vehicles</span></li>
                                                        <li><span>Dedicated Visitor Parking</span></li>
                                                        <li><span>Washroom Facilities</span></li>
                                                        <li><span>24*7 Security</span></li>
                                                    </ul>
                                                </div>
                                                <div className="price__doc mt-20 mb-40"></div>
                                                <div className="row align-items-center">
                                                    <div className="col-xl-6">
                                                        <div className="price__features mb-10">
                                                            <b className="d-block">Rental</b>
                                                        </div>
                                                        <div className="price__tag">
                                                            <div className="d-flex flex-wrap">
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>22,000/-</h1>
                                                                <span className="ms-1">Without Service</span>
                                                            </div>
                                                            <div className="d-flex flex-wrap mt-5">
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>30,000/-</h1>
                                                                <span className="ms-1">With Service</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="text-lg-end mt-lg-0 mt-30">
                                                            <Link to="/contact" className="z-btn">Book Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="portfolioBox sticky-top">
                                {imagesLoc3.slice(0, 3).map((img, index) => (
                                    <div key={index} className="portfolio__item p-relative">
                                        <div className="portfolio__thumb w-img fix">
                                            <img src={img} alt="Premisin" />
                                            <div className="portfolio__plus p-absolute transition-3">
                                                <button
                                                    onClick={() => {
                                                        openLightbox(index);
                                                        setImages(imagesLoc3);
                                                    }}
                                                >
                                                    <i><AiOutlinePlus /></i>
                                                    <i><AiOutlinePlus /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <span>+9 More</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="price__area grey-bg-12 pt-100 pb-100 fixBox" id="4000">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Fully Furnished Office Space for Rent in Vallabh Nagar Raipur - Ready To Move Available</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="expart__wrapper">
                                <div className="expart__tab">
                                    <div className="tab-content">
                                        <div className="expart__tab-content white-bg">
                                            <div className="expart__content">
                                                <h3>Location - C245/6, Vallabh Nagar, Near Priyadarshini Nagar, Pachpedi Naka, Raipur, Chhattisgarh 492001</h3>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                                    <ul>
                                                        <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>1,10,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>5 months</b></span></li>
                                                        <li><span>Built-up Area - <b>4000 sq.ft</b></span></li>
                                                        <li><span>Carpet Area - <b>3200 sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-3">4000 sq.ft Fully Furnished office for lease is available in Pachpedi Naka, Raipur. This office space offers a professionally designed and ready-to-use environment, ideal for businesses looking for a convenient and comfortable workspace. The elegance of marble flooring with thoughtful workstation layout and the refreshing recreational area on the terrace, 24* 7 security this features has been carefully considered to create an inspiring and comfortable setting for work and collaboration. The location of this office space provides excellent connectivity to major commercial areas, making it an attractive choice for businesses in Raipur.</p>
                                                <div className="price__features">
                                                    <b className="d-block mb-10">Property Highlights:</b>
                                                    <ul>
                                                        <li><span>4,000 sq.ft Excellent Office Space with all the essential amenities</span></li>
                                                        <li><span>30-35 Workstations (Air Conditioned Workspace)</span></li>
                                                        <li><span>6 spacious cubicles</span></li>
                                                        <li><span>10 seater conference room, with modern presentation</span></li>
                                                        <li><span>1 Small meeting room</span></li>
                                                        <li><span>1 Director Cabin</span></li>
                                                        <li><span>1 cabins for sr. executives 8 seater</span></li>
                                                        <li><span>DG Backup for uninterrupted power supply (Chargeable)</span></li>
                                                        <li><span>1 pantry for employees to enjoy refreshments.</span></li>
                                                        <li><span>Recreational Zone (Play Area - Pool Table, Fosse Ball, Table Tennis Etc., Community Access)</span></li>
                                                        <li><span>Access to waiting Area, common lobby and Reception</span></li>
                                                        <li><span>Lifts for convenience</span></li>
                                                        <li><span>Washroom Facility</span></li>
                                                        <li><span>Ample Parking for Both 2 and 4-Wheeler Vehicles</span></li>
                                                        <li><span>24*7 security for safety assurance (Security Guard)</span></li>
                                                        <li><span>Compliance with fire safety regulations</span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-0 mt-3"><b><i>** 3 small cabins available at workspace will not be provided and will be kept locked.</i></b></p>
                                                <div className="price__doc mt-20 mb-40"></div>
                                                <div className="row align-items-center">
                                                    <div className="col-xl-6">
                                                        <div className="price__features mb-10">
                                                            <b className="d-block">Rental</b>
                                                        </div>
                                                        <div className="price__tag">
                                                            <div>
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>1,10,000/-</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="text-lg-end mt-lg-0 mt-30">
                                                            <Link to="/contact" className="z-btn">Book Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="portfolioBox sticky-top">
                                {imagesLoc4.slice(0, 3).map((img, index) => (
                                    <div key={index} className="portfolio__item p-relative">
                                        <div className="portfolio__thumb w-img fix">
                                            <img src={img} alt="Premisin" />
                                            <div className="portfolio__plus p-absolute transition-3">
                                                <button
                                                    onClick={() => {
                                                        openLightbox(index);
                                                        setImages(imagesLoc4);
                                                    }}
                                                >
                                                    <i><AiOutlinePlus /></i>
                                                    <i><AiOutlinePlus /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <span>+14 More</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PropertySection;