import React from 'react';

const HeadSection = () => {
    return (
        <>
            <section className="price__area black-bg-2 pt-60 pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title text-center">
                                <h1 className="white-color mb-0">Office Space in Kamal Vihar</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeadSection;