import React from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineCurrencyRupee } from "react-icons/md";

const PlanSection = () => {
    return (
        <>
            <section className="price__area pt-100 pb-100" id="spaces">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-3 text-center mb-55">
                                <h2>Select a Office Location that aligns with your requirements</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img1.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Vallabh Nagar</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                        <ul>
                                            <li><span>Rental - With Service <b><i><MdOutlineCurrencyRupee /></i>18,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>4 months</b></span></li>
                                            <li><span>Built-up Area - <b>200 sq.ft</b></span></li>
                                            <li><span>Carpet Area - <b>150 sq.ft</b></span></li>
                                            <li className="d-none d-lg-block">&nbsp;</li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-vallabh-nagar" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img2.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Vallabh Nagar</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                        <ul>
                                            <li><span>Rental - Without Service <b><i><MdOutlineCurrencyRupee /></i>20,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>4 months</b></span></li>
                                            <li><span>Built-up Area - <b>400 sq.ft</b></span></li>
                                            <li><span>Carpet Area - <b>300 sq.ft</b></span></li>
                                            <li className="d-none d-lg-block">&nbsp;</li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-vallabh-nagar" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img3.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Vallabh Nagar</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                        <ul>
                                            <li><span>Rental - Without Service <b><i><MdOutlineCurrencyRupee /></i>22,000/-</b></span></li>
                                            <li><span>Rental - With Service <b><i><MdOutlineCurrencyRupee /></i>30,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>4 months</b></span></li>
                                            <li><span>Built-up Area - <b>800 sq.ft</b></span></li>
                                            <li><span>Carpet Area - <b>700 sq.ft</b></span></li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-vallabh-nagar" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img4.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Vallabh Nagar</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                        <ul>
                                            <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>1,10,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>5 months</b></span></li>
                                            <li><span>Built-up Area - <b>4000 sq.ft</b></span></li>
                                            <li><span>Carpet Area - <b>3200 sq.ft</b></span></li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-vallabh-nagar" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img5.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Kamal Vihar</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Unfurnished Office Space for Rent</b>
                                        <ul>
                                            <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>40,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>4 months</b></span></li>
                                            <li><span>Built-up Area - <b>2000 sq.ft</b></span></li>
                                            <li><span>Carpet Area - <b>2000 sq.ft</b></span></li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-kamal-vihar" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img6.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Kamal Vihar</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                        <ul>
                                            <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>70,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>4 months</b></span></li>
                                            <li><span>Built-up Area - <b>2000 sq.ft</b></span></li>
                                            <li><span>Carpet Area - <b>2000 sq.ft</b></span></li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-kamal-vihar" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img7.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Kamal Vihar</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Unfurnished Office Space for Rent</b>
                                        <ul>
                                            <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>1,00,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>4 months</b></span></li>
                                            <li><span>Built-up Area - <b>6000 sq.ft</b></span></li>
                                            <li><span>Carpet Area - <b>6000 sq.ft</b></span></li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-kamal-vihar" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img8.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Kamal Vihar</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                        <ul>
                                            <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>2,00,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>4 months</b></span></li>
                                            <li><span>Built-up Area - <b>6000 sq.ft</b></span></li>
                                            <li><span>Carpet Area - <b>6000 sq.ft</b></span></li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-kamal-vihar" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img9.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Kamal Vihar</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Unfurnished Bareshell Office for Rent</b>
                                        <ul>
                                            <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>90,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>4 months</b></span></li>
                                            <li><span>Built-up Area - <b>4800 sq.ft</b></span></li>
                                            <li><span>Maintenance - <b><i><MdOutlineCurrencyRupee /></i>2 per sq.ft</b></span></li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-kamal-vihar" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix mb-30 mb-lg-0">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img10.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Kamal Vihar</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                        <ul>
                                            <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>2,00,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>5 months</b></span></li>
                                            <li><span>Built-up Area - <b>6000 sq.ft</b></span></li>
                                            <li><span>Maintenance - <b><i><MdOutlineCurrencyRupee /></i>3 per sq.ft</b></span></li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-kamal-vihar" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="price__item p-relative transition-3 fix">
                                <div className="m-img r-img">
                                    <img src="assets/img/office-space-img11.jpg" alt="sjain" />
                                </div>
                                <div className="price__inner">
                                    <p>Office Space in Rio Mall</p>
                                    <div className="price__features mb-20">
                                        <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                        <ul>
                                            <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>45,000/-</b></span></li>
                                            <li><span>Security Deposit - <b>4 months</b></span></li>
                                            <li><span>Built-up Area - <b>1872 sq.ft</b></span></li>
                                            <li className="d-none d-lg-block">&nbsp;</li>
                                        </ul>
                                    </div>
                                    <Link to="/office-space-in-rio-mall" className="price-btn">Know More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PlanSection;