import React from 'react';
import PageHelmet from '../Helper/PageHelmet';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import HeadSection from './HeadSection';
import PropertySection from './PropertySection';
import ProcessSection from '../Home/ProcessSection';

const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="Office Space in Kamal Vihar" />
            <Header />
            <HeadSection />
            <PropertySection />
            <ProcessSection />
            <Footer />
        </>
    );
};

export default Home;