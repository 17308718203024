import React from 'react';
import PageHelmet from '../Helper/PageHelmet';
import Header from '../Helper/Header';
import Footer from '../Helper/Footer';

import HeadSection from './HeadSection';
import PlanSection from '../Home/PlanSection';
import ContactSection from '../Home/ContactSection';

const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="Discover Our Office Spaces" />
            <Header />
            <HeadSection />
            <PlanSection />
            <ContactSection />
            <Footer />
        </>
    );
};

export default Home;