import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollTop from "./components/Helper/ScrollTop";
import BackToTop from "./components/Helper/BackToTop";
import Error from './components/Error';
import Home from './components/Home/index';
import About from './components/About/index';
import Contact from './components/Contact/index';

import OfficeSpaces from './components/OfficeSpaces/index';
import VallabhNagar from './components/VallabhNagar/index';
import KamalVihar from './components/KamalVihar/index';
import RioMall from './components/RioMall/index';

function App() {
    return (
        <>
            <Router>
                <ScrollTop />
                <Routes>
                    <Route path="*" element={<Error />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/office-spaces" element={<OfficeSpaces />} />
                    <Route path="/office-space-in-vallabh-nagar" element={<VallabhNagar />} />
                    <Route path="/office-space-in-kamal-vihar" element={<KamalVihar />} />
                    <Route path="/office-space-in-rio-mall" element={<RioMall />} />
                </Routes>
                <BackToTop />
            </Router>
        </>
    );
}

export default App;
