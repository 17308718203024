import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { AiOutlinePlus } from 'react-icons/ai';
import Lightbox from 'react-image-lightbox';

const imagesLoc1 = [
    'assets/img/location/rio-mall-img1.jpg',
    'assets/img/location/rio-mall-img2.jpg',
    'assets/img/location/rio-mall-img3.jpg',
    'assets/img/location/rio-mall-img4.jpg',
    'assets/img/location/rio-mall-img5.jpg',
    'assets/img/location/rio-mall-img6.jpg',
    'assets/img/location/rio-mall-img7.jpg',
    'assets/img/location/rio-mall-img8.jpg',
    'assets/img/location/rio-mall-img9.jpg',
];

const PropertySection = () => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [images, setImages] = useState(imagesLoc1);
    const [isOpen, setIsOpen] = useState(false);
    const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    return (
        <>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}
            <section className="price__area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__title section__title-4">
                                <h2>Fully Furnished Private Office for Rent in Rio Mall Raipur - Budget Friendly</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="expart__wrapper">
                                <div className="expart__tab">
                                    <div className="tab-content">
                                        <div className="expart__tab-content white-bg">
                                            <div className="expart__content">
                                                <h3>Location - 2<sup>nd</sup> Floor, Rio Mall, Lalpur, Raipur, Chhattisgarh 492001</h3>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Fully Furnished Office Space for Rent</b>
                                                    <ul>
                                                        <li><span>Rental - <b><i><MdOutlineCurrencyRupee /></i>45,000/-</b></span></li>
                                                        <li><span>Security Deposit - <b>4 months</b></span></li>
                                                        <li><span>Built-up Area - <b>1872 sq.ft</b></span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-3">A spacious and fully furnished office space for rent available in Rio mall, Raipur, spanning 1872 sq.ft. This office space offers a professionally designed and ready-to-use environment, ideal for businesses looking for a convenient and comfortable workspace. The location of this office space provides excellent connectivity to major commercial areas, making it an attractive choice for businesses seeking a prime location in Raipur.</p>
                                                <div className="price__features mb-20">
                                                    <b className="d-block mb-10">Property Highlights:</b>
                                                    <ul>
                                                        <li><span>Excellent commercial property</span></li>
                                                        <li><span>Private office space of 1872 sq.ft.</span></li>
                                                        <li><span>Thoughtfully furnished interiors</span></li>
                                                        <li><span>Modern amenities and infrastructure</span></li>
                                                        <li><span>3 cabins for executives or team leaders</span></li>
                                                        <li><span>1 dedicated 10-seater conference room</span></li>
                                                        <li><span>14-18 workstations, allowing for a dynamic atmosphere</span></li>
                                                        <li><span>1 large Reception area</span></li>
                                                        <li><span>Air conditioned workspace</span></li>
                                                        <li><span>1 pantry</span></li>
                                                        <li><span>Compliance with fire safety regulations</span></li>
                                                        <li><span>Passenger lift for easy accessibility</span></li>
                                                        <li><span>1 washrooms</span></li>
                                                        <li><span>Ample parking space for 2 & 4-wheelers</span></li>
                                                    </ul>
                                                </div>
                                                <p className="mb-0 mt-3"><b><i>** 3 small cabins in the workspace will be kept lock.</i></b></p>
                                                <div className="price__doc mt-20 mb-40"></div>
                                                <div className="row align-items-center">
                                                    <div className="col-xl-6">
                                                        <div className="price__features mb-10">
                                                            <b className="d-block">Rental</b>
                                                        </div>
                                                        <div className="price__tag">
                                                            <div>
                                                                <h1 className="m-0"><i><MdOutlineCurrencyRupee /></i>45,000/-</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="text-lg-end mt-lg-0 mt-30">
                                                            <Link to="/contact" className="z-btn">Book Now</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="portfolioBox sticky-top">
                                {imagesLoc1.slice(0, 3).map((img, index) => (
                                    <div key={index} className="portfolio__item p-relative">
                                        <div className="portfolio__thumb w-img fix">
                                            <img src={img} alt="Premisin" />
                                            <div className="portfolio__plus p-absolute transition-3">
                                                <button
                                                    onClick={() => {
                                                        openLightbox(index);
                                                        setImages(imagesLoc1);
                                                    }}
                                                >
                                                    <i><AiOutlinePlus /></i>
                                                    <i><AiOutlinePlus /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <span>+6 More</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PropertySection;