import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sidebar from './Sidebar';
import useGlobalContext from '../../hooks/useGlobalContext';

const Header = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { stickyMenu } = useGlobalContext();
    const scrollTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
        });
    };

    return (
        <>
            <header>
                <div className="header__area">
                    <div id="header__sticky" className={stickyMenu ? "sticky header__bottom header__bottom-2" : "header__bottom header__bottom-2"}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="logo-3">
                                        <NavLink to="/">
                                            <img src="assets/img/logo.png" alt="sjain" />
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                                    <div className="header__bottom-right d-flex justify-content-end align-items-center">
                                        <div className="main-menu main-menu-3 menu_three_wrapper">
                                            <nav id="mobile-menu">
                                                <ul>
                                                    <li><NavLink to="/">Home</NavLink></li>
                                                    <li className="inactive"><NavLink to="/" onClick={() => scrollTo("spaces")}>Office Spaces</NavLink></li>
                                                    <li><NavLink to="/about">About Us</NavLink></li>
                                                    <li><NavLink to="/contact">Contact Us</NavLink></li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="header__btn d-none d-sm-block d-xl-block ml-50">
                                            <Link to="/contact" className="z-btn z-btn-3">Get a Quote</Link>
                                        </div>
                                        <div onClick={handleShow} className="sidebar__menu d-lg-none">
                                            <div className="sidebar-toggle-btn sidebar-toggle-btn-3" id="sidebar-toggle">
                                                <span className="line"></span>
                                                <span className="line"></span>
                                                <span className="line"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header >

            <Sidebar show={show} handleClose={handleClose} />
        </>
    );
};

export default Header;